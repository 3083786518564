@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,500,600,700,800,800i,900i&display=swap');

*,
*::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

h1 {
  @include page-title;
}

h2 {
  @include page-subtitle;
}

h3 {
  @include sub-subtitle;
}
button.ant-btn {
  border-radius: $border-radius-btn;
}

input.ant-input,
.ant-input-password,
div.ant-select-selector,
textarea.ant-input {
  border-radius: $border-radius-btn !important;
}

.ant-modal-content {
  border-radius: $border-radius-btn;
}

.page-container {
  padding: 20px;
  background-color: $white;
}

.ant-form-item-label > label::after {
  display: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &::before {
    display: none;
  }
  &::after {
    @include required-label;
  }
}

.ant-modal-confirm-warning,
.ant-modal-confirm-confirm {
  .ant-modal-confirm-body {
    & > .anticon {
      color: $red-color !important;
    }
  }
}

button.ant-btn.full-width-btn {
  width: 100%;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.main-content {
  background-color: $white;
  padding: 0.7rem 1rem;

  .label {
    color: $dark-grayish-blue;
  }

  h3 {
    margin-bottom: 2rem;
  }

  #buttons-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.ant-form-horizontal .ant-form-item-label {
  text-align: left;
}

.ant-message-custom-content {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ant-row.ant-form-item.submit,
.ant-form-item-control-input-content > button.ant-btn {
  width: 100% !important;
}

.ant-pagination-item-active {
  background-color: $dark-grayish-blue;
  border-color: $dark-grayish-blue;

  & > a {
    color: $white;
  }
}

.ant-pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.big-select {
  @include big-select;
  .ant-form-item-children-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.ant-pagination-item-active {
  background-color: $dark-grayish-blue;
  border-color: $dark-grayish-blue;

  & > a {
    color: $white;
  }
}

.ant-pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.big-select {
  @include big-select;
}

.buttons-block-label {
  font: $btn-font;
  letter-spacing: 0px;
  color: $label-text-color;
  margin-bottom: 8px;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
