@import '@styles/variables';
@import '@styles/mixins';

.required::after {
  @include required-label;
}

.input-layout-row {
  &.ant-row {
    margin-bottom: 20px;
  }
}
