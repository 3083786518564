@import '@styles/variables';

.ant-table {
  .ant-table-thead > tr > th {
    background-color: #f5f6fa;
    color: #a3a6b4;
    font: bold 13px/17px $font-family;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ant-table-tbody > tr > td {
    color: $label-text-color;
    font: $small-font;
    padding-top: 7px;
    padding-bottom: 7px;

    svg {
      color: #a5a4bf;
      height: 27px;
      width: 27px;
    }
  }

  img {
    height: 35px;
    border-radius: 50%;
  }

  .ant-checkbox-inner {
    border: 2px solid #0000001a;
    border-radius: 3px;
  }
}
