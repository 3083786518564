@import '@styles/variables';

.buttons-block-container {
  .buttons-block-label {
    font: $btn-font;
    letter-spacing: 0px;
    color: $label-text-color;
  }
  .buttons-block {
    display: flex;
    width: 100%;
    height: 50px;
    margin: 4px 0 10.6px;

    .ant-btn {
      height: 100%;
      flex: 1;
      font: $btn-font;
      letter-spacing: 0px;
      color: $text-color;
      border: $bold-border;
      border-radius: 0px;
      &.active {
        background-color: $text-color;
        color: $white;
      }
      &:not(:last-child) {
        border-right: none;
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      span {
        pointer-events: none;
      }
    }
  }
}
