@import '@styles/variables';

.ant-layout {
  #company-select {
    width: 180px;
  }

  label[for='company-select'] {
    margin-right: 10px;
  }

  .ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: $white;
    padding: 0;
    height: '70px';

    .ant-btn {
      &.focus {
        border-color: $antd-btn-color;
        color: $antd-btn-color;
      }

      a:hover {
        color: none;
      }
    }

    .end-container {
      display: flex;
      align-items: center;
      margin: 0 45px;
      gap: 26px;
      color: $label-text-color;
      font: $small-font;

      .ant-divider {
        height: 20px;
      }

      .name-logout-btn-block,
      .company-select-block {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
}
.ant-tooltip.ant-tooltip-placement-bottom {
  .ant-tooltip-inner > p {
    margin: 0;
    padding: 0;
  }
}
