@mixin page-title {
  margin-bottom: 15.6px;
  font: $title-font;
  color: $dark-grayish-blue;
}

@mixin page-subtitle {
  font: $subtitle-font;
  color: $dark-grayish-blue;
}

@mixin sub-subtitle {
  font: $sub-subtitle-font;
  color: $dark-grayish-blue;
}

@mixin required-label-font {
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}

@mixin required-label {
  display: inline-block;
  margin-left: 4px;
  color: $red-color;
  @include required-label-font;
  content: '*';
}

@mixin big-select {
  .select-search-label {
    font: $btn-font;
    letter-spacing: 0px;
    color: $label-text-color;
  }

  .ant-select {
    border: $bold-border !important;
    border-radius: $border-radius-btn;
    width: 100%;
    height: 100%;

    .ant-select-selector {
      height: 50px;
      align-items: center;

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        text-align: center;
        font: $big-select-font;
        letter-spacing: 0px;
        color: $text-color;
      }

      .ant-select-selection-search-input {
        font: $big-select-font;
        letter-spacing: 0px;
        color: $text-color;
        margin-top: 9px;
      }

      .ant-select-selection-placeholder {
        color: $label-text-color-light;
        font: $standard-font;
      }
    }
    .ant-select-arrow {
      color: $text-color;
      width: 28px;
      height: 28px;
      top: 18px;

      .anticon-down {
        path {
          d: path(
            'M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z'
          );
        }
      }

      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
}
