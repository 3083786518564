.debounce-custom-select {
  display: flex;
  flex-direction: row;

  .ant-select {
    width: 100%;
  }

  .input-container {
    position: relative;
    width: 100%;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -60px;
      top: 0;
    }
  }

  .delete-input-container {
    @extend .input-container;
    margin-bottom: 10px;
  }
}
