@import '@styles/variables';

.user-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $dark-grayish-blue;
  color: $white;
}
