@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,700,800,800i,900i&display=swap);

// colors
$white: #ffffff;
$dark-grayish-blue: #43425d;
$main-layout-color: #f0f0f7;
$text-color: #2f2e50;
$label-text-color: #4d4f5c;
$label-text-color-light: #797b86;
$red-color: #ff4d4f;
$antd-btn-color: #40a9ff;
$light-blue: #3b86ff;
$placeholder-gray: #e8e9ec;
$shadow-box: #0000000a;
$dashboard-block: #34324b;
$dashboard-block-active: #414bb2;
$gray-border: #d9d9d9;

//standard font family
$font-family: 'Source Sans Pro', sans-serif;

//fonts
$standard-font: normal 15px/21px $font-family;
$small-font: normal 13px/20px $font-family;
$btn-font: normal 18px/13px $font-family;
$big-select-font: normal 18px/27px $font-family;
$title-font: bold 28px/40px $font-family;
$subtitle-font: bold 24px/36px $font-family;
$sub-subtitle-font: bold 20px/30px $font-family;

//borders
$bold-border: 2px solid $text-color;

//border radius
$border-radius-btn: 4px;
