@import '@styles/variables';

aside.ant-layout-sider {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: $dark-grayish-blue;

  + * {
    padding-left: 260px;
  }

  .ant-layout-sider-children {
    height: 100vh;

    .sidebar-logo-content {
      background: $dark-grayish-blue;
      display: flex;
      align-items: center;
      overflow: hidden;
      height: 70px;
      padding-left: 24px;

      span {
        color: $white;
        font: $standard-font;
      }
    }

    .menu-container {
      background: $dark-grayish-blue;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 70px);

      .bottom-menu {
        padding-bottom: 15px;
      }

      .ant-menu {
        background-color: $dark-grayish-blue;

        .ant-menu-item {
          font: $standard-font;
          white-space: normal;
          height: 53px;
          margin: 3px 0;
          a {
            display: flex;
            align-items: center;
          }
        }
      }
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          font: $standard-font;
          height: 50px;
          margin: 3px 0;
        }
        .ant-menu-sub {
          background-color: $dark-grayish-blue;
          .subitem-text {
            font: $standard-font;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
